var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reviews"},[_c('v-container',{class:{
      'mt-4 mb-5': _vm.$vuetify.breakpoint.smAndDown,
      'mt-7 mb-11': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_c('base-subheading',[_vm._v(" "+_vm._s(_vm.$t("pages_specific.main_page.they_trust_us.title"))+" ")]),_c('v-row',{class:{
        'mt-3': _vm.$vuetify.breakpoint.smAndDown,
        'mt-7': _vm.$vuetify.breakpoint.mdAndUp,
      }},_vm._l((_vm.reviews_published),function(item,i){return _c('div',{key:i,staticClass:"review col-12 col-md-4 pb-2",class:{
          'pl-3 pr-3 mt-0':
            _vm.$vuetify.breakpoint.xsOnly && _vm.$vuetify.breakpoint.width >= 360,
          'col-12': _vm.$vuetify.breakpoint.width < 360,
        }},[_c('card',{staticClass:"pa-0 pa-sm-4",attrs:{"outlined":_vm.$vuetify.breakpoint.mdAndUp,"elevation_0":!_vm.$vuetify.breakpoint.mdAndUp}},[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_c('img',{staticClass:"pl-2 pr-4",class:{
                'restrict-height': _vm.$vuetify.breakpoint.smAndUp,
                'restrict-height-xs': _vm.$vuetify.breakpoint.xsOnly,
              },attrs:{"alt":item.title,"src":require('@/assets/' + item.logo)}})]),_c('h4',{staticClass:"h4_cards"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"pb-5"},[_c('p',{domProps:{"innerHTML":_vm._s(item.text)}})])])],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }