<template>
  <div class="reviews">
    <v-container
      :class="{
        'mt-4 mb-5': $vuetify.breakpoint.smAndDown,
        'mt-7 mb-11': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <base-subheading>
        {{ $t("pages_specific.main_page.they_trust_us.title") }}
      </base-subheading>

      <v-row
        :class="{
          'mt-3': $vuetify.breakpoint.smAndDown,
          'mt-7': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <div
          v-for="(item, i) in reviews_published"
          :key="i"
          class="review col-12 col-md-4 pb-2"
          :class="{
            'pl-3 pr-3 mt-0':
              $vuetify.breakpoint.xsOnly && $vuetify.breakpoint.width >= 360,
            'col-12': $vuetify.breakpoint.width < 360,
          }"
        >
          <card
            :outlined="$vuetify.breakpoint.mdAndUp"
            :elevation_0="!$vuetify.breakpoint.mdAndUp"
            class="pa-0 pa-sm-4"
          >
            <a :href="item.link" target="_blank">
              <img
                class="pl-2 pr-4"
                :class="{
                  'restrict-height': $vuetify.breakpoint.smAndUp,
                  'restrict-height-xs': $vuetify.breakpoint.xsOnly,
                }"
                :alt="item.title"
                :src="require('@/assets/' + item.logo)"
              />
            </a>

            <h4 class="h4_cards">
              {{ item.title }}
            </h4>

            <div class="pb-5">
              <p v-html="item.text"></p>
            </div>
          </card>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Card from "../custom_styling/Card.vue";

export default {
  components: { Card },
  computed: {
    reviews_published() {
      const all_reviews = this.$t(
        "pages_specific.main_page.they_trust_us.companies_list"
      );
      var filtred_reviews = [];
      for (const review of all_reviews) {
        if (review.display && "false" != review.display)
          filtred_reviews.push(review);
      }
      return filtred_reviews;
    },
  },
};
</script>

<style scoped>
.review {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
}

.v-card {
  padding: 1em 1em 0 1em;
}

.restrict-height {
  max-height: 70px;
  max-width: 100%;
}

.restrict-height-xs {
  max-height: 50px;
  max-width: 80%;
}

.steps {
  margin-top: 2em;
}

.step:hover h4 {
  background-color: #f1d135;
}
.step:hover .v-card {
  background-color: #f9f9f9;
}

.step {
  min-width: min-content;
  cursor: pointer;
}

.border-bottom {
  border-bottom: thin solid #e0e0e0;
  padding-bottom: 2em;
  margin-bottom: 1em;
}
</style>